import React from 'react';
import styled from '@emotion/styled';

import { BREAKPOINTS, MOBILE_SIDE_PADDING, WEB_SIDE_PADDING } from 'utils/constants';

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  maxWidth: 500,
  padding: `150px ${WEB_SIDE_PADDING} 0 ${WEB_SIDE_PADDING}`,
  [BREAKPOINTS[800]]: {
    paddingLeft: MOBILE_SIDE_PADDING,
    paddingRight: MOBILE_SIDE_PADDING,
  },
});

const Title = styled.div((props) => ({
  color: props.theme.colors.white,
  fontSize: 40,
  lineHeight: '60px',
  fontWeight: 600,
  marginBottom: 24,
}));

const Text = styled.div((props) => ({
  color: props.theme.colors.white,
  fontSize: 14,
  lineHeight: '28px',
  fontWeight: 400,
}));

const EmailUnsubscribe: React.FC = () => (
  <Container>
    <Title>Thank you</Title>
    <Text>
      You have been successfully removed from this subscriber list and won’t receive any further
      emails from us.
    </Text>
  </Container>
);

export default EmailUnsubscribe;
